import React from 'react'
import MainContentLayout from "../../../Layouts/Layout";
import {Head, usePage} from '@inertiajs/inertia-react'
import {Inertia} from "@inertiajs/inertia";
import axios from "axios";

export default function Index({storeUrl, markets, groups, vatTypes, success, randomDataUrlSI, randomDataUrlHR, randomDataUrlRS, randomDataUrlIT, randomDataUrlUS})
{
    const {errors} = usePage().props

    if (success) {
        alert('Tenant created successfully');
    }

    console.log(errors);
    const FormDataField = {
        tenant: {
            name: 'tenant.name',
            market: 'tenant.market',
        },
        user: {
            username: 'user.username',
            password: 'user.password',
            name: 'user.name',
            group: 'user.group',
            phone: 'user.phone',
            email: 'user.email',
            vat_number: 'user.vat_number',
        },
        organization: {
            name: 'organization.name',
            vat_code: 'organization.vat_code',
            owner: 'organization.owner',
            address: 'organization.address',
            city: 'organization.city',
            zip: 'organization.zip',
            email: 'organization.email',
            phone: 'organization.phone',
            vat_type: 'organization.vat_type',
            vat_excluded_description: 'organization.vat_excluded_description',
            configuration: 'organization.configuration',
            state: 'organization.state',
        }
    };


    const handleGetData = (randomDataUrl) => {
        axios.get(randomDataUrl).then(data => {
            const fillInputData = (querySelector, value) => {
                document.querySelector(querySelector).value = value;
            };

            console.log(data.data);

            fillInputData(`input[name="${FormDataField.tenant.name}"]`, data.data.tenant.name);
            fillInputData(`select[name="${FormDataField.tenant.market}"]`, data.data.tenant.market);

            fillInputData(`input[name="${FormDataField.user.username}"]`, data.data.user.username);
            fillInputData(`input[name="${FormDataField.user.password}"]`, data.data.user.password);
            fillInputData(`input[name="${FormDataField.user.name}"]`, data.data.user.name);
            fillInputData(`select[name="${FormDataField.user.group}"]`, data.data.user.group.toLowerCase());
            fillInputData(`input[name="${FormDataField.user.phone}"]`, data.data.user.phone);
            fillInputData(`input[name="${FormDataField.user.email}"]`, data.data.user.email);
            fillInputData(`input[name="${FormDataField.user.vat_number}"]`, data.data.user.vat_number);

            fillInputData(`input[name="${FormDataField.organization.name}"]`, data.data.organization.name);
            fillInputData(`input[name="${FormDataField.organization.vat_code}"]`, data.data.organization.vat_code);

            fillInputData(`input[name="${FormDataField.organization.owner}"]`, data.data.organization.owner);
            fillInputData(`input[name="${FormDataField.organization.address}"]`, data.data.organization.address);
            fillInputData(`input[name="${FormDataField.organization.city}"]`, data.data.organization.city);
            fillInputData(`input[name="${FormDataField.organization.zip}"]`, data.data.organization.zip);
            fillInputData(`input[name="${FormDataField.organization.email}"]`, data.data.organization.email);
            fillInputData(`input[name="${FormDataField.organization.phone}"]`, data.data.organization.phone);
            fillInputData(`select[name="${FormDataField.organization.vat_type}"]`, data.data.organization.vat_type);
            fillInputData(`input[name="${FormDataField.organization.vat_excluded_description}"]`, data.data.organization.vat_excluded_description);
            fillInputData(`input[name="${FormDataField.organization.state}"]`, data.data.organization.state);
        });
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData(e.currentTarget);
        const data = Object.fromEntries(formData.entries());

        const tenant = Object.fromEntries(
            Object.entries(data)
                .filter(([key, value]) => key.startsWith('tenant.'))
                .map(([key, value]) => [key.replace('tenant.', ''), value])
        );

        const user = Object.fromEntries(
            Object.entries(data)
                .filter(([key, value]) => key.startsWith('user.'))
                .map(([key, value]) => [key.replace('user.', ''), value])
        );

        const organization = Object.fromEntries(
            Object.entries(data)
                .filter(([key, value]) => key.startsWith('organization.'))
                .map(([key, value]) => [key.replace('organization.', ''), value])
        );

        if (organization.vat_type !== '0') {
            delete organization.vat_excluded_description;
        }

        Inertia.post(storeUrl, { tenant, user, organization });
    }

    const marketOptions = markets.map(market => {
        return <option value={market.value}>{market.name}</option>
    });

    const groupOptions = groups.map(group => {
        return <option value={group.value}>{group.name}</option>
    });

    const vatTypeOptions = vatTypes.map(vatType => {
        return <option value={vatType.value}>{vatType.name}</option>
    });

    const tenantForm = (
        <>
            <h1 className="p-2 text-2xl font-bold">Tenant</h1>

            <label className="flex px-2 items-center justify-between gap-x-4 py-2">
                <span>Name</span>
                <div className="max-w-sm basis-full">
                    <input
                        type="text"
                        placeholder="m777"
                        name={FormDataField.tenant.name}
                        className="bg-white w-full py-1 px-2 border rounded-lg" />

                    <div className="text-sm text-red-500">{errors[FormDataField.tenant.name]}</div>
                </div>
            </label>

            <label className="flex px-2 items-center justify-between gap-x-4 py-2">
        <span>Market</span>
        <div className="max-w-sm basis-full">
            <select
                name={FormDataField.tenant.market}
                className="bg-white w-full py-1 px-2 border rounded-lg"
            >
                {marketOptions}
            </select>

            <div className="text-sm text-red-500">{errors[FormDataField.tenant.market]}</div>
        </div>
    </label>
        </>
    );

    const userForm = (
        <>
            <h1 className="p-2 mt-5 text-2xl font-bold">User</h1>

            <label className="flex px-2 items-center justify-between gap-x-4 py-2">
                <span>Username</span>
                <div className="max-w-sm basis-full">
                    <input
                        type="text"
                        placeholder="admin"
                        name={FormDataField.user.username}
                        className="bg-white w-full py-1 px-2 border rounded-lg" />

                    <div className="text-sm text-red-500">{errors[FormDataField.user.username]}</div>
                </div>
            </label>
            <label className="flex px-2 items-center justify-between gap-x-4 py-2">
                <span>Password</span>
                <div className="max-w-sm basis-full">
                    <input
                        type="text"
                        placeholder="12345678"
                        name={FormDataField.user.password}
                        className="bg-white w-full py-1 px-2 border rounded-lg" />

                    <div className="text-sm text-red-500">{errors[FormDataField.user.password]}</div>
                </div>
            </label>
            <label className="flex px-2 items-center justify-between gap-x-4 py-2">
                <span>Name</span>
                <div className="max-w-sm basis-full">
                    <input
                        type="text"
                        name={FormDataField.user.name}
                        placeholder="Test User"
                        className="bg-white w-full py-1 px-2 border rounded-lg" />

                    <div className="text-sm text-red-500">{errors[FormDataField.user.name]}</div>
                </div>
            </label>
            <label className="flex px-2 items-center justify-between gap-x-4 py-2">
                <span>Group</span>
                <div className="max-w-sm basis-full">
                    <select
                        name={FormDataField.user.group}
                        className="bg-white w-full py-1 px-2 border rounded-lg"
                    >
                        {groupOptions}
                    </select>

                    <div className="text-sm text-red-500">{errors[FormDataField.user.group]}</div>
                </div>
            </label>
            <label className="flex px-2 items-center justify-between gap-x-4 py-2">
                <span>Phone</span>
                <div className="max-w-sm basis-full">
                    <input
                        type="text"
                        name={FormDataField.user.phone}
                        className="bg-white w-full py-1 px-2 border rounded-lg" />

                    <div className="text-sm text-red-500">{errors[FormDataField.user.phone]}</div>
                </div>
            </label>
            <label className="flex px-2 items-center justify-between gap-x-4 py-2">
                <span>Email</span>
                <div className="max-w-sm basis-full">
                    <input
                        type="text"
                        placeholder="a@a.com"
                        name={FormDataField.user.email}
                        className="bg-white w-full py-1 px-2 border rounded-lg" />

                    <div className="text-sm text-red-500">{errors[FormDataField.user.email]}</div>
                </div>
            </label>
            <label className="flex px-2 items-center justify-between gap-x-4 py-2">
                <span>VAT Number</span>
                <div className="max-w-sm basis-full">
                    <input
                        type="text"
                        name={FormDataField.user.vat_number}
                        className="bg-white w-full py-1 px-2 border rounded-lg" />

                    <div className="text-sm text-red-500">{errors[FormDataField.user.vat_number]}</div>
                </div>
            </label>
        </>
    );

    const organizationForm = (
        <>
            <h1 className="p-2 text-2xl font-bold">Organization</h1>

            <label className="flex px-2 items-center justify-between gap-x-4 py-2">
                <span>Name</span>
                <div className="max-w-sm basis-full">
                    <input
                        type="text"
                        placeholder="Test Organization d.o.o."
                        name={FormDataField.organization.name}
                        className="bg-white w-full py-1 px-2 border rounded-lg"/>

                    <div className="text-sm text-red-500">{errors[FormDataField.organization.name]}</div>
                </div>
            </label>
            <label className="flex px-2 items-center justify-between gap-x-4 py-2">
                <span>VAT Code</span>
                <div className="max-w-sm basis-full">
                    <input
                        type="text"
                        placeholder="..."
                        name={FormDataField.organization.vat_code}
                        className="bg-white w-full py-1 px-2 border rounded-lg"/>

                    <div className="text-sm text-red-500">{errors[FormDataField.organization.vat_code]}</div>
                </div>
            </label>
            <label className="flex px-2 items-center justify-between gap-x-4 py-2">
                <span>Owner</span>
                <div className="max-w-sm basis-full">
                    <input
                        type="text"
                        name={FormDataField.organization.owner}
                        className="bg-white w-full py-1 px-2 border rounded-lg"/>

                    <div className="text-sm text-red-500">{errors[FormDataField.organization.owner]}</div>
                </div>
            </label>
            <label className="flex px-2 items-center justify-between gap-x-4 py-2">
                <span>Address</span>
                <div className="max-w-sm basis-full">
                    <input
                        type="text"
                        name={FormDataField.organization.address}
                        className="bg-white w-full py-1 px-2 border rounded-lg"/>

                    <div className="text-sm text-red-500">{errors[FormDataField.organization.address]}</div>
                </div>
            </label>
            <label className="flex px-2 items-center justify-between gap-x-4 py-2">
                <span>City</span>
                <div className="max-w-sm basis-full">
                    <input
                        type="text"
                        name={FormDataField.organization.city}
                        className="bg-white w-full py-1 px-2 border rounded-lg"/>

                    <div className="text-sm text-red-500">{errors[FormDataField.organization.city]}</div>
                </div>
            </label>
            <label className="flex px-2 items-center justify-between gap-x-4 py-2">
                <span>Zip</span>
                <div className="max-w-sm basis-full">
                    <input
                        type="text"
                        name={FormDataField.organization.zip}
                        className="bg-white w-full py-1 px-2 border rounded-lg"/>

                    <div className="text-sm text-red-500">{errors[FormDataField.organization.zip]}</div>
                </div>
            </label>
            <label className="flex px-2 items-center justify-between gap-x-4 py-2">
                <span>Email</span>
                <div className="max-w-sm basis-full">
                    <input
                        type="text"
                        name={FormDataField.organization.email}
                        className="bg-white w-full py-1 px-2 border rounded-lg"/>

                    <div className="text-sm text-red-500">{errors[FormDataField.organization.email]}</div>
                </div>
            </label>
            <label className="flex px-2 items-center justify-between gap-x-4 py-2">
                <span>Phone</span>
                <div className="max-w-sm basis-full">
                    <input
                        type="text"
                        name={FormDataField.organization.phone}
                        className="bg-white w-full py-1 px-2 border rounded-lg"/>

                    <div className="text-sm text-red-500">{errors[FormDataField.organization.phone]}</div>
                </div>
            </label>
            <label className="flex px-2 items-center justify-between gap-x-4 py-2">
                <span>VAT Type</span>
                <div className="max-w-sm basis-full">
                    <select
                        name={FormDataField.organization.vat_type}
                        className="bg-white w-full py-1 px-2 border rounded-lg"
                    >
                        {vatTypeOptions}
                    </select>

                    <div className="text-sm text-red-500">{errors[FormDataField.organization.vat_type]}</div>
                </div>
            </label>
            <label className="flex px-2 items-center justify-between gap-x-4 py-2">
                <span>VAT Excluded Description</span>
                <div className="max-w-sm basis-full">
                    <input
                        type="text"
                        placeholder="test"
                        name={FormDataField.organization.vat_excluded_description}
                        className="bg-white w-full py-1 px-2 border rounded-lg"/>

                    <div
                        className="text-sm text-red-500">{errors[FormDataField.organization.vat_excluded_description]}</div>
                </div>
            </label>
            <label className="flex px-2 items-center justify-between gap-x-4 py-2">
                <span>State (only for US)</span>
                <div className="max-w-sm basis-full">
                    <input
                        type="text"
                        placeholder="test"
                        name={FormDataField.organization.state}
                        className="bg-white w-full py-1 px-2 border rounded-lg"/>

                    <div
                        className="text-sm text-red-500">{errors[FormDataField.organization.state]}</div>
                </div>
            </label>
        </>
    );

    return (
        <main>
            <Head title="Create Tenant"/>

            <div className="flex justify-end gap-x-4 py-2">
                <button onClick={() => handleGetData(randomDataUrlSI)}
                        type="button"
                        className="px-4 py-2 bg-green-500 text-white rounded-lg">
                    Generate Random SI Data
                </button>
                <button onClick={() => handleGetData(randomDataUrlRS)}
                        type="button"
                        className="px-4 py-2 bg-green-500 text-white rounded-lg">
                    Generate Random RS Data
                </button>
                <button onClick={() => handleGetData(randomDataUrlUS)}
                        type="button"
                        className="px-4 py-2 bg-green-500 text-white rounded-lg">
                    Generate Random US Data
                </button>
            </div>

            <div className="container text-lg m-auto">
                <form onSubmit={handleSubmit} className="mx-auto max-w-6xl">
                    <div className="flex px-5 justify-between gap-x-4">
                        <div className="basis-full">
                        {tenantForm}
                            {userForm}
                        </div>
                        <div className="basis-full">
                            {organizationForm}
                        </div>
                    </div>
                    <div className="flex justify-end gap-x-4 py-2">
                        <button type="submit" className="px-4 py-2 bg-green-500 text-white rounded-lg">Create</button>
                    </div>
                </form>
            </div>

        </main>
    )
}

Index.layout = page => (
    <MainContentLayout children={page} />
)
